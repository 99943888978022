import Layout from "../../components/structural/layout"
import PropTypes from "prop-types"
import React from "react"
import SectionGameHero from "../../components/sections/sectionGameHero"
import SectionHero from "../../components/sections/sectionHero"
import Sections from "../../components/sections/sections"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"

const Game = props => {
  const { data, pageContext } = props

  let story = data?.storyblokEntry ? data.storyblokEntry : pageContext.story
  story = useStoryblokState(story)

  const { content } = story
  const {
    highlight_colour,
    desktop_background_image,
    mobile_background_image,
    desktop_video,
    mobile_video,
    app_store_list,

    content: sections,
  } = content

  return (
    <Layout>
      {/* <SectionGameHero
        desktop_background_image={desktop_background_image}
        mobile_background_image={mobile_background_image}
        desktop_video={desktop_video}
        mobile_video={mobile_video}
        app_store_list={app_store_list}
      /> */}
      <SectionHero
        blok={{
          desktop_image: desktop_background_image,
          mobile_image: mobile_background_image,
          desktop_video: desktop_video,
          mobile_video: mobile_video,
          app_store_list: app_store_list,
        }}
      />

      {sections && (
        <div className="bg-se-black">
          <Sections highlightColor={highlight_colour?.color} items={sections} />
        </div>
      )}
    </Layout>
  )
}

export default Game

export const Head = ({ data, pageContext }) => {
  const story = data?.storyblokEntry ? data.storyblokEntry : pageContext.story

  const { seo, seo_author, seo_image, seo_keywords } = story?.content

  const siteSettings =
    data?.settings?.content && JSON.parse(data.settings.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
      keywords={seo_keywords}
    />
  )
}

export const QUERY = graphql`
  query GamePageQuery($lang: String) {
    settings: storyblokEntry(
      full_slug: { eq: "site-settings" }
      lang: { eq: $lang }
    ) {
      content
      name
      full_slug
      uuid
      id
      internalId
      lang
    }
  }
`

Game.defaultProps = {
  data: null,
  pageContext: null,
}

Game.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}
